<template>
  <div class="payDialog">
    <el-dialog title="添加地址" width="720px" :visible.sync="addressDialogShow" @close="closeAddressDialog">
      <div class="inputs">
        <el-form :inline="true" :model="formData">
          <el-form-item>
            <el-input v-model="formData.send_name" type="text" placeholder="姓名" style="width: 100px"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="formData.send_phone" type="text" placeholder="手机号" style="width: 150px"></el-input>
          </el-form-item>
          <el-form-item>
            <el-cascader size="large" :options="options" v-model="selectedOptions" @change="handleChange"> </el-cascader>
          </el-form-item>
          <el-form-item>
            <el-input v-model="formData.send_address" type="text" placeholder="详细地址，街道，楼栋等" style="width: 280px"></el-input>
          </el-form-item>
        </el-form>
        <el-button @click="addAddress" type="primary" size="small">确认添加</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addArea } from 'network/api'
import { regionData, CodeToText } from 'element-china-area-data'
export default {
  data () {
    return {
      dialogVisible: false,
      formData: {
        send_name: '',
        send_phone: '',
        send_province: '',
        send_city: '',
        send_town: '',
        send_address: ''
      },
      options: regionData,
      selectedOptions: []
    }
  },
  props: {
    addressDialogShow: { default: false }
  },
  methods: {
    closeAddressDialog () {
      this.$emit('closeAddressDialog')
    },
    // 点击添加地址
    addAddress () {
      for (const key in this.formData) {
        // 判断值是否为空
        if (!this.formData[key]) {
          this.$message({
            message: '请完善信息',
            type: 'warning'
          })
          return false
        }
      }
      // 验证手机号
      if (!(/^1[3|4|5|7|8][0-9]{9}$/.test(this.formData.send_phone))) {
        this.$message({
          message: '手机号码错误',
          type: 'warning'
        })
      } else {
        // 正确则添加地址
        addArea(this.formData).then(res => {
          if (res.code === 1) {
            this.$message({
              message: res.msg,
              type: 'success'
            })
          }
        })
        this.$emit('updateAddressDialog') // 更新列表
      }
    },
    // 三级联动回调
    handleChange (val) {
      this.formData.send_province = CodeToText[val[0]]
      this.formData.send_city = CodeToText[val[1]]
      this.formData.send_town = CodeToText[val[2]]
    }
  }
}
</script>

<style scoped src="styles/components/payDialog.css">
</style>

<style>
</style>
